<template>
  <v-theme-provider dark>
    <base-section
      id="team"
      class="blue-grey lighten-4"
      space="40"
    >
    <v-toolbar
      flat
      color="primary"
      dark
    >
      <v-toolbar-title>Our Team</v-toolbar-title>
    </v-toolbar>
    <v-card flat class="blue-grey lighten-5">
      <v-card-text>
        <v-container>
          <v-row>
            
            <v-col
              v-for="member in teamData" 
              :key="member.sortorder"
              cols="12"
              lg="3"
              md="4"
              sm="6"
            >
              <teamMember :teammember="member.data" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
      <!-- <v-card outlined>
        <v-toolbar
          flat
          color="primary"
          dark
        >
          <v-toolbar-title>Teams</v-toolbar-title>
        </v-toolbar>
        <v-tabs vertical>
          <v-tab
            v-for="team in teams.filter(t=>t.active)"
            :key="team.dbName"
            active-class="teamTabActive"
          >
            <v-icon
              v-if="team.icon"
              left
            >
              {{ team.icon }}
            </v-icon>
            {{ team.name }}
          </v-tab>
          <v-tab-item
            v-for="team in teams.filter(t=>t.active)"
            :key="team.dbName"
          >
            <v-card flat>
              <v-card-text>
                <v-card-title>
                  {{ team.name }}
                </v-card-title>
                <v-container>
                  <v-row>
                    
                    <v-col
                      v-for="member in teamData.filter(d=>d.data.team===team.dbName)" 
                      :key="member.sortorder"
                      cols="12"
                      lg="3"
                      md="4"
                      sm="6"
                    >
                      <teamMember :teammember="member.data" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <v-container v-if="false">
        <v-row
          v-for="team in teams.filter(t=>t.active)"
          :key="team.dbName"
          justify="center"
        >
          <v-col cols="10">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              :title="team.name"
              class="brown lighten-1"
            />
            <base-body space="0">
              <v-container>
                <v-row>
                  <v-col
                    v-for="member in teamData.filter(d=>d.data.team===team.dbName)"
                    :key="member.sortorder"
                    cols="4"
                    md="4"
                  >
                    <teamMember :teammember="member.data" />
                  </v-col>
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container> -->
    </base-section>
  </v-theme-provider>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { FirebaseTeam } from '@/database/'
  import teamMember from '@/components/TeamMember'

  export default {
    name: 'Team',
    components: {
      teamMember,
    },
    data: () => ({
      teamData: [],
      teams: [
        { name: 'Management', dbName: 'Manager', active: true },
        { name: 'International', dbName: 'International', active: false },
        { name: 'Team Red', dbName: 'Loan Officer 1', active: false },
        { name: 'Team Blue', dbName: 'Loan Officer 2', active: false },
        { name: 'Loan Officer', dbName: 'Loan Officer', active: true },
      ],
    }),
    computed: {
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.getTeam()
      },
      getTeam () {
        this.teamData = []
        FirebaseTeam.where('active', '==', true).where('ShowCommercial', '==', true)
          .orderBy('sortorder')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.teamData.push({
                id: doc.id,
                data: doc.data(),
              })
            })
            // console.log(this.teamData)
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      },
    },
  }
</script>
<style scoped>
  .teamTabActive {
    background-color:#c6c6c6;
    color:black;
    font-weight:bold;
    border-radius: 25px;
  }
</style>

<template>
  <div class="teammember">
    <router-link :to="'/team/'+teammember.id">
      <img
        :src="'/images/employees/' + teammember.imagename"
        alt="employee"
      /><br />
      <span class="teammembername">{{ teammember.name }}</span>
    </router-link>
    <br />
    <span v-html="teammember.title" /> <br />
    <span v-if="teammember.location != '' && false">
      {{ teammember.location }}<br />
    </span>
    NMLS {{ teammember.nmls }} <br />
    <a
      :href="'mailto:' + teammember.email"
    >{{ teammember.email }}</a> <br />
    <a
      :href="'tel:' + teammember.phone"
    >{{ teammember.phonetype }} {{ teammember.phone }}</a>
  </div>
</template>

<script>
  export default {
    name: 'TeamMember',
    props: {
      teammember: Object,
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.teammember {
  color: rgb(99, 99, 99);
  text-align: center;
  font-size: small;
  line-height: 34px;
}

.teammembername {
  font-size: x-large;
}
</style>
